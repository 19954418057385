import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/barbershop.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Barber Shops | Camelo"
        metaDescription="Scheduling software for barbershops. Organize upcoming shifts quickly, create schedules in minutes, & ensure accurate payroll. Try free today!"
        header="Cut the time spent on scheduling."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
